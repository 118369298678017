<template>
    <div v-if="propItem.type == 'boolean'" class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" v-model="propItem.selectedValue" :id="'id_'+propItem.name" :disabled="propItem.disabled" >
        <label class="form-check-label" :for="'id_'+propItem.name">
            {{propItem.name}}
            
        </label>
        <button v-if="propItem.description" type="button" class="btn btn-sm btn-link" v-popover data-bs-toggle="popover" data-bs-trigger="focus" :data-bs-title="propItem.name" :data-bs-content="propItem.description">
                <i class="bi bi-question-circle"></i>
        </button>

    </div>
    <div v-else-if="propItem.values" class="row g-3 align-items-center">
        <div class="col-2">
            <label class="form-check-label" :for="'id_'+propItem.name">
                {{propItem.name}}<span v-if="propItem.required" class="text-danger" title="This prop is required">*</span>
            
            </label>
            <button v-if="propItem.description" type="button" class="btn btn-sm btn-link" v-popover data-bs-toggle="popover" data-bs-trigger="focus" :data-bs-title="propItem.name" :data-bs-content="propItem.description"><i class="bi bi-question-circle"></i></button>
        </div>
        <div class="col-10">
            <select class="form-select form-select-sm rounded-0" v-model="propItem.selectedValue" :disabled="propItem.disabled" >
                <option value="">No value</option>
                <option :value="val" v-for="val in propItem.values">{{val}}</option>
            </select>
        </div>
    </div>
    <div v-else class="row g-3 align-items-center">
        <div class="col-2">
            <label :for="'id_propITem_'+propItem.name" class=" col-form-label">{{propItem.name}}</label>
        
            <button v-if="propItem.description" type="button" class="btn btn-sm btn-link" v-popover data-bs-toggle="popover" data-bs-trigger="focus" :data-bs-title="propItem.name" :data-bs-content="propItem.description">
                <i class="bi bi-question-circle"></i>
            </button>
        </div>
        <div class="col-10">
            <input type="text" class="form-control form-control-sm rounded-0" :disabled="propItem.disabled"  :id="'id_propITem_'+propItem.name" v-model="propItem.selectedValue">
        </div>
    </div>
</template>
<script setup>
    import {watch} from 'vue';
    import vPopover from 'o365.vue.directive.popover.js';
    const props = defineProps({
        propItem:{
            
            required:true
        },
    
    });
    const emit = defineEmits(['propChange']);

    watch(props,newItem=>{
        emit('propChange');
    })

 

  

    
</script>